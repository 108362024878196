<template>
  <section>
    <div class="searchwrap">
       <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span> :</div>
           <date-selector :class="'searchbar-content'"
                          @setStartDate="setStartDate"
                          @setEndDate="setEndDate"
                          :startDefault="startDefault"
                          :endDefault="endDefault"
                          :configs="dateConfigs"
                          :isOldYn="true"
                          @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
         </div>
         <div class="box-ui-select searchbar-content">
           <div class="title">
             <i class="fas fa-cog"></i>
             <span>{{ $t('searchArea.id') }}</span> :
           </div>
           <input type="text" class="mr-5" v-model="reqData.memId" :placeholder="$t('searchArea.id')" />
           <button class="btn-search" type="button" @click="pageSeach">
             <i class="fas fa-search"></i>
           </button>
         </div>

         <div class="box-ui-toggle searchbar-content">
           <div class="title">
             <i class="fas fa-cog"></i>
             <span>{{$t('common.condition')}}</span> :
           </div>
           <div class="toggle-container">
             <div class="toggle-item">
               <span class="toggle-title">{{$t('common.includeBlack')}}</span>
               <input type="checkbox" class="set-switch" v-model="reqData.memBlackYn" />
             </div>
             <div class="toggle-item">
               <span class="toggle-title">{{$t('common.includeDelMem')}}</span>
               <input type="checkbox" class="set-switch" v-model="reqData.memDelYn" />
             </div>
           </div>
         </div>
       </div>
       <div class="searchBar">
         <div class="box-ui-check">
           <input type="checkbox" id="game_all" value="all" @change="gameAllChk" v-model="gameAllYn" />
           <label for="game_all">
             <i class="fas fa-check"></i>
             <span>{{ $t('searchArea.total') }}</span>
           </label>
           <template v-for="item in gameList" :key="item.gameType">
             <input type="checkbox" :id="`game_${item.gameType}`" v-model="item.yn" />
             <label :for="`game_${item.gameType}`">
               <i class="fas fa-check"></i>
               <span>{{ item.gameTxtKey }}</span>
             </label>
           </template>
         </div>
       </div>
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody class="family-wrap" v-for="(item, idx) in tableData" :key="item.memId">
            <template v-if="totalWlYn">
              <tr>
                <td>{{ idx + 1 }}</td>
                <td>{{ item.memId }}</td>
                <td>{{ numberWithCommas(item.totalData.betCnt) }}</td>
                <td>{{ numberWithCommas(item.totalData.betAmt) }}</td>
                <!-- <td>{{ item.totalData.spBetAmt }}</td> -->
                <td :class="item.totalData.wlAmt > 0 ? 'fc-blue' : 'fc-red'">{{ numberWithCommas(item.totalData.wlAmt) }}</td>
                <td :class="item.totalData.result > 0 ? 'fc-blue' : 'fc-red'">{{ numberWithCommas(item.totalData.result) }}</td>
              </tr>
            </template>
          </tbody>
          <tbody class="family-wrap total">
            <tr>
              <td :colspan="totalWlYn ? 2 : 3">{{ $t('common.total') }}</td>
              <td>{{ numberWithCommas(totalData.betCnt) }}</td>
              <td>{{ numberWithCommas(totalData.betAmt) }}</td>
              <!-- <td>{{numberWithCommas(totalData.spBetAmt)}}</td> -->
              <td :class="totalData.wlAmt > 0 ? 'fc-blue' : 'fc-red'">{{ numberWithCommas(totalData.wlAmt) }}</td>
              <td :class="totalData.result > 0 ? 'fc-blue' : 'fc-red'">{{ numberWithCommas(totalData.result) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import UiCheck from '@/components/ui/UiCheckSet'
import subTitle from '@/components/main/PageSubTitle'
import { reportWl, getCode } from '@/api/member.js'
import { GAME_INFO_LIST, GAME_CODE_NAME } from '@/libs/constants'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'

export default {
  name: 'BetList',
  components: {
    SearchFilter,
    DateSelector,
    TableHead,
    UiCheck,
    subTitle,
    Pagination
  },
  data: function () {
    return {
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'memId', 'betCnt', 'betAmt', 'wl', 'contractorTotal']
      },
      reqData: {
        page: 1,
        count_per_list: '30',
        memId: '',
        memDelYn: 'N',
        memBlackYn: 'N',
        codeList: [],
        startDate: '',
        endDate: ''
      },
      pageInfo: {},
      codeList: [],
      convertData: {},
      gameType: {},
      totalWlYn: true,
      currentPage: '',
      gameList: [],
      gameAllYn: true,
      totalData: {
        betCnt: 0,
        betAmt: 0,
        spBetAmt: 0,
        wlAmt: 0,
        result: 0
      },
      startDefault: '',
      endDefault: ''
    }
  },
  watch: {
    totalWlYn () {
      if (!this.totalWlYn) {
        if (this.headInfo.dataList.indexOf('betType') === -1) {
          this.headInfo.dataList.splice(2, 0, 'betType')
        }
      } else {
        this.headInfo.dataList.splice(2, 1)
      }
    },
    gameList: {
      deep: true,
      handler (gameList) {
        for (const item of gameList) {
          if (!item.yn) {
            this.gameAllYn = false
            break
          }
        }
      }
    }
  },
  methods: {
    gameAllChk (e) {
      const target = e.target
      const val = target.checked
      console.log(val)
      for (const item of this.gameList) {
        item.yn = val
      }
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd 00:00:00')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd 23:59:59')
    },
    async getGameCode (masterCode) {
      return await getCode(masterCode)
    },
    async setGameList () {
      const gameList = await this.getGameCode('kplay')
      // console.log(gameList);
      if (gameList.length !== 0) {
        for (const item of gameList) {
          const groupCode = item.groupCode
          const gameCode = item.code
          // const gTxtCode = GAME_INFO_LIST[groupCode][gameCode.toString()];
          // if (gTxtCode) {
          const gameTextKey = item.codeName
          const rateObj = {
            gameTxtKey: gameTextKey,
            gameType: gameCode,
            yn: true
          }
          if (item.useYn === 'Y') {
            this.gameList.push(rateObj)
          }
          // }
        }
      }
    },
    pageSeach () {
      this.setTableData(1)
    },
    async setTableData (pageNum) {
      this.emitter.emit('Loading', true)
      if (pageNum) {
        this.reqData.page = pageNum
      }
      const codeList = []
      for (const item of this.gameList) {
        if (item.yn) {
          codeList.push(item.gameType)
        }
      }
      this.reqData.codeList = codeList
      const req = lodash.cloneDeep(this.reqData)
      for (const key in req) {
        if (typeof req[key] === 'boolean') {
          if (req[key]) {
            req[key] = ''
          } else {
            req[key] = 'N'
          }
        }
      }
      console.log(req)
      const res = await reportWl(req)
      console.log(res)
      if (res.resultCode === '0') {
        this.tableData = this.dataRefactor(res.data.list)
        this.pageInfo = res.pageInfo
      }
      this.emitter.emit('Loading', false)
    },
    dataRefactor (dataList) {
      console.log(dataList.length)
      if (dataList.length !== 0) {
        const memList = []
        this.totalData.betCnt = 0
        this.totalData.betAmt = 0
        this.totalData.spBetAmt = 0
        this.totalData.wlAmt = 0
        this.totalData.result = 0
        for (const item of dataList) {
          this.totalData.betCnt += item.betCnt
          this.totalData.betAmt += item.betAmt
          this.totalData.spBetAmt += item.spBetAmt
          this.totalData.wlAmt += item.wlAmt
          this.totalData.result += item.wlAmt * -1
          if (memList.indexOf(item.memId) === -1) {
            // console.log('push id')
            const obj = {
              memId: item.memId,
              list: [],
              totalData: {}
            }
            memList.push(obj)
          }
        }
        // console.log(memList);
        for (const obj of memList) {
          for (const data of dataList) {
            if (obj.memId === data.memId) {
              const _obj = {
                gameType: data.gameType,
                betCnt: data.betCnt,
                betAmt: data.betAmt,
                spBetAmt: data.spBetAmt,
                wlAmt: data.wlAmt,
                result: data.wlAmt * -1
              }
              for (const gText of this.gameList) {
                if (gText.gameType === data.gameType) {
                  _obj.gameTxtKey = gText.gameTxtKey
                }
              }
              obj.list.push(_obj)
            }
          }
        }
        for (const mem of memList) {
          const totalData = {
            betCnt: 0,
            spBetAmt: 0,
            betAmt: 0,
            wlAmt: 0,
            result: 0
          }
          for (const list of mem.list) {
            totalData.betCnt += list.betCnt
            totalData.betAmt += list.betAmt
            totalData.spBetAmt += list.spBetAmt
            totalData.wlAmt += list.wlAmt
            totalData.result += list.result
          }
          mem.totalData = totalData
        }
        console.log(memList)
        return memList
      } else {
        this.totalData = {
          betCnt: 0,
          betAmt: 0,
          spBetAmt: 0,
          wlAmt: 0,
          result: 0
        }
        return []
      }
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.setStartDate()
    this.setEndDate()
    await this.setGameList()
    await this.setTableData(1)
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.searchBar:first-child {border-bottom: 1px solid #e1e0e0;}
.box-ui-check {padding-top: 20px;}
.box-ui-check input[type="checkbox"] + label {flex: 0 0 10%;margin-bottom: 20px;}
</style>
